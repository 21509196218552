import { differenceInYears } from "date-fns";
import jwt from "jsonwebtoken";
import get from "lodash/get";
import set from "lodash/set";
import { Cookies } from "react-cookie";

import { getAuthToken } from "@/api/user";
import { COOKIES } from "@/constants";
import { isValidPhone } from "@/utils/helpers/validations";

import styles from "../../components/equifit_summary/index.module.scss";
import { baseEnvironmentConfig } from "../constants";

const Utilities = {
  getToken() {
    const bavToken = new Cookies().get(COOKIES.USER_SESSION_ACCESS_TOKEN.name);
    const decodedToken = bavToken && this.decodeJwtToken();
    return decodedToken;
  },

  redirectToPaymentApp(
    redirectUrl,
    flag,
    productToken,
    authToken = null,
    referrerCookieValue = "/gift-card/purchase"
  ) {
    const cookieOptions = {
      path: "/",
      maxAge: 1200,
      domain:
        process.env.NEXT_PUBLIC_ENVIRONMENT === "localhost"
          ? "localhost"
          : ".equinox.com",
      secure: process.env.NEXT_PUBLIC_ENVIRONMENT !== "localhost",
    };
    if (flag) {
      new Cookies().set(
        baseEnvironmentConfig.session.cookies.paymentAppReferrer,
        referrerCookieValue,
        cookieOptions
      );
      // for GC login flow
      new Cookies().remove(
        baseEnvironmentConfig.session.cookies.paymentAppProductToken
      );
      new Cookies().set(
        baseEnvironmentConfig.session.cookies.paymentAppProductToken,
        productToken,
        cookieOptions
      );
      authToken &&
        new Cookies().set("EqxWeb.authOToken", authToken, cookieOptions);
    }
    window.location.href = redirectUrl;
  },

  redirectToAccount() {
    window.location.href = `${window.location.origin}/account${
      this.getUrlParams().token ? `?token=${this.getUrlParams().token}` : ""
    }`;
  },
  redirectToGiftCard() {
    window.location.href = `${window.location.origin}/gift-card/purchase`;
  },
  getCurrentTimeStamp() {
    return new Date().getTime();
  },
  decodeJwtToken(fromPath, saveReturnUrl) {
    return jwt.decode(getAuthToken(fromPath, saveReturnUrl));
  },
  getUrlParams() {
    window.receiveNearby = this.receiveNearby;
    const params = {};
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          getNearby: "window.receiveNearby",
        })
      );
    if (window.authToken) {
      params.token = window.authToken;
    }
    return params;
  },

  parseUrlParams(id) {
    const params = {};
    const pi = Buffer.from(id, "base64").toString();
    const vars = pi.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  },
  totalBodyWaterData: [
    {
      id: 0,
      range: [0, 49],
      color: styles.color6,
      tag: "below",
      domain: "< 50",
    },

    {
      id: 1,
      range: [50, 100],
      color: styles.color3,
      tag: "Normal",
      domain: "50-100",
    },
  ],
  bodyFatMassDataFemale: [
    {
      id: 1,
      range: [0, 17.8],
      color: styles.color1,
      tag: "excellent",
      domain: "<17.9",
    },
    {
      id: 2,
      range: [17.9, 21.4],
      color: styles.color2,
      tag: "good",
      domain: "<21.5",
    },
    {
      id: 3,
      range: [21.5, 24.7],
      color: styles.color3,
      tag: "average",
      domain: "<24.8",
    },
    {
      id: 4,
      range: [24.8, 29.1],
      color: styles.color4,
      tag: "below average",
      domain: "<29.2",
    },
    {
      id: 5,
      range: [29.2, 100],
      color: styles.color5,
      tag: "poor",
      domain: "<100.0",
    },
  ],
  bodyFatNorms: {
    Male: [
      {
        age: [0, 29],
        norms: [
          [0, 9.4, "excellent"],
          [9.4, 14.1, "good"],
          [14.1, 17.6, "average"],
          [17.6, 22.5, "below average"],
          [22.5, 100.1, "poor"],
        ],
      },
      {
        age: [30, 39],
        norms: [
          [0, 13.9, "excellent"],
          [13.9, 17.5, "good"],
          [17.5, 20.5, "average"],
          [20.5, 24.2, "below average"],
          [24.2, 100.1, "poor"],
        ],
      },
      {
        age: [40, 49],
        norms: [
          [0, 16.3, "excellent"],
          [16.3, 19.6, "good"],
          [19.6, 22.5, "average"],
          [22.5, 26.1, "below average"],
          [26.1, 100.1, "poor"],
        ],
      },
      {
        age: [50, 59],
        norms: [
          [0, 17.9, "excellent"],
          [17.9, 21.3, "good"],
          [21.3, 24.1, "average"],
          [24.1, 27.5, "below average"],
          [27.5, 100.1, "poor"],
        ],
      },
      {
        age: [60, Infinity],
        norms: [
          [0, 18.4, "excellent"],
          [18.4, 22, "good"],
          [22, 25, "average"],
          [25, 28.5, "below average"],
          [28.5, 100.1, "poor"],
        ],
      },
    ],
    Female: [
      {
        age: [0, 29],
        norms: [
          [0, 17.1, "excellent"],
          [17.1, 20.6, "good"],
          [20.6, 23.7, "average"],
          [23.7, 27.7, "below average"],
          [27.7, 100.1, "poor"],
        ],
      },
      {
        age: [30, 39],
        norms: [
          [0, 18, "excellent"],
          [18, 21.6, "good"],
          [21.6, 24.9, "average"],
          [24.9, 29.3, "below average"],
          [29.3, 100.1, "poor"],
        ],
      },
      {
        age: [40, 49],
        norms: [
          [0, 21.3, "excellent"],
          [21.3, 24.9, "good"],
          [24.9, 28.1, "average"],
          [28.1, 32.1, "below average"],
          [32.1, 100.1, "poor"],
        ],
      },
      {
        age: [50, 59],
        norms: [
          [0, 24.9, "excellent"],
          [25, 28.5, "good"],
          [28.5, 31.6, "average"],
          [31.6, 35.6, "below average"],
          [35.6, 100.1, "poor"],
        ],
      },
      {
        age: [60, Infinity],
        norms: [
          [0, 25.1, "excellent"],
          [25.1, 29.3, "good"],
          [29.3, 32.5, "average"],
          [32.5, 36.6, "below average"],
          [36.6, 100.1, "poor"],
        ],
      },
    ],
    "Prefer not to say": [
      {
        age: [0, Infinity],
        norms: [[0, 100.1, "undefined"]],
      },
    ],
    Other: [
      {
        age: [0, Infinity],
        norms: [[0, 100.1, "undefined"]],
      },
    ],
    Select: [
      {
        age: [0, Infinity],
        norms: [[0, 100.1, "undefined"]],
      },
    ],
  },
  restingHeartRateData: [
    {
      id: 0,
      range: [0, 59],
      color: styles.color3,
      tag: "Athletic",
      domain: "<60",
    },
    {
      id: 1,
      range: [60, 79],
      color: styles.color5,
      tag: "Normal",
      domain: "60-80",
    },
    {
      id: 2,
      range: [80, 300],
      color: styles.color6,
      tag: "High",
      domain: ">80",
    },
  ],
  bloodPressureData: [
    {
      id: 0,
      range: [0, 120, 0, 80],
      color: styles.color3,
      tag: "Normal",
      rangeOperator: "and",
      domain: "<120 and <80",
    },
    {
      id: 1,
      range: [120, 129, 0, 81],
      color: styles.color4,
      tag: "Elevated",
      rangeOperator: "and",
      domain: "120-129 and <81",
    },
    {
      id: 2,
      range: [130, 139, 81, 89],
      color: styles.color5,
      tag: "High Stage 1",
      rangeOperator: "or",
      domain: "130-139 or 81-89",
    },
    {
      id: 3,
      range: [140, 300, 90, 200],
      color: styles.color6,
      tag: "High Stage 2",
      rangeOperator: "or",
      domain: ">= 140 or >= 90",
    },
  ],
  mapMeasurements(summaryData, isGeneralInfo) {
    const object =
      summaryData.find((v) =>
        [isGeneralInfo, "Baseline Testing", "Resting Assessments"].includes(
          v.title
        )
      ) || {};
    const keys = object.data && Object.keys(object.data);
    const objectArray = [];
    keys &&
      keys.forEach((v) => {
        let data = {};
        data = {
          label:
            get(object.schema, `${v}.titleHTML`) ||
            get(object.schema, `${v}.title`),
          value: Number(get(object.data, v)),
          unit: get(object.schema, `${v}.help`, ""),
        };
        objectArray.push(data);
      });
    return objectArray;
  },
  mapFMSMeasurements(summaryData) {
    const object =
      summaryData.find((v) => v.title === "Functional Movement Screen") || {};
    const keys = object.data && Object.keys(object.data);
    const objectArray = [];
    keys &&
      keys.forEach((v) => {
        let data = {};
        const fmsvalue = get(object.data, v);
        const options = get(object.schema, `${v}.options`, "");
        let mapvalue = 0;
        options &&
          options.map((op) => {
            if (op.val == fmsvalue) {
              mapvalue = Number(op.label);
            }
          });
        data = {
          label:
            get(object.schema, `${v}.titleHTML`) ||
            get(object.schema, `${v}.title`),
          unit: get(object.schema, `${v}.help`, ""),
          mapvalue,
        };
        objectArray.push(data);
      });
    return objectArray;
  },
  getPointerPosition(value, data, dualRange) {
    const activeIndex =
      (dualRange
        ? data.find((v) => {
            if (v.rangeOperator === "and") {
              return (
                v.range[0] < value[0] &&
                v.range[1] > value[0] &&
                v.range[2] < value[1] &&
                v.range[3] > value[1]
              );
            }

            return (
              (value[0] >= v.range[0] && value[0] < v.range[1]) ||
              (value[1] >= v.range[2] && value[1] < v.range[3])
            );
          })
        : data.find((v) => v.range[0] < value[0] && v.range[1] > value[0])) ||
      {};

    const xStart =
      activeIndex.id * (data && data[0].tag === "Normal" ? 70 : 60);
    const xRange =
      get(activeIndex, "range.1", 0) - get(activeIndex, "range.0", 0);
    const actualValue = dualRange ? value[1] : value[0];
    const x =
      xStart +
      (Math.max(0, actualValue - get(activeIndex, "range.0", 0)) / xRange) * 50;
    activeIndex.xTransition = x;
    return activeIndex;
  },
  getPersonalInfo(summaryData) {
    const object =
      summaryData.find((v) => v.title === "General Information") || {};
    const personal01 = get(object, "data.personal01");
    const dob = get(object, "data.personal02");
    const memberAge = differenceInYears(new Date(), dob);
    const schema = get(object, "schema.personal01.options", []);
    const gender = schema.find((v) => v.val === personal01) || {};
    return {
      gender: get(gender, "label", ""),
      age: memberAge,
    };
  },
  getBodyFatNormsData(summaryData) {
    const memberPersonalInfo = this.getPersonalInfo(summaryData);
    const age = memberPersonalInfo.age;
    const genderData = get(this.bodyFatNorms, memberPersonalInfo.gender, []);
    const measurements =
      genderData.find((v) => v.age[0] <= age && v.age[1] >= age) || {};
    return (
      measurements.norms &&
      measurements.norms.map((step, i) => ({
        tag: step[2],
        domain: "<" + (step[1] - 0.1).toFixed(1),
        range: [step[0], step[1]],
        text: "",
        color: get(styles, `color${i + 1}`),
        id: i,
      }))
    );
  },
  mapIconImages(icons, label) {
    const object = {};
    icons.map((v) => {
      const key = get(v, `fields.imageFile.fields.${label}`);
      const value = get(v, `fields.imageFile.fields.file.url`);
      set(object, key, value);
    });
    return object;
  },
  templateIds: [
    { templateId: 10352, templateName: "Informed Consent" },
    { templateId: 10355, templateName: "Personal Information" },
    { templateId: 10357, templateName: "Goals" },
    { templateId: 10359, templateName: "Exercise History" },
    { templateId: 10361, templateName: "Lifestyle" },
    { templateId: 10365, templateName: "Medical Orthopedic History" },
    { templateId: 10370, templateName: "Body Measurements" },
    { templateId: 10372, templateName: "Functional Movement Screen" },
    { templateId: 10374, templateName: "Performance Testing" },
  ],
  scrollToTop(position) {
    window.scroll({
      top: position,
      behavior: "smooth",
    });
  },
  weekDays: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
    "Weekday",
  ],
  canadianProvince: [
    "AB",
    "BC",
    "MB",
    "NB",
    "NL",
    "NT",
    "NS",
    "NU",
    "ON",
    "PE",
    "QC",
    "SK",
    "YT",
  ],
  getApiHost() {
    const { origin } = window.location;
    const baseUrl = origin.includes("qa-armstrong")
      ? "https://stag-api.equinox.com"
      : baseEnvironmentConfig.equinox_api.host;
    return baseUrl;
  },
  getPaymentHost() {
    const { origin } = window.location;
    const baseUrl = origin.includes("qa-armstrong")
      ? "https://stag-payment.equinox.com"
      : process.env.NEXT_PUBLIC_PAYMENT_APP_URL;
    return baseUrl;
  },
  regexExp: {
    ukPostalCode:
      /^(GIR ?0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/i,
    canadaZipCode:
      /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/i,
    generalZipCode: /^\d{5}$|^\d{5}-\d{4}/,
    charactersWithDigits: /^[a-zA-Z\d]'?([a-zA-Z\d]|\.| |-|,|'|"|“|”)+$/,
    characters: /^[a-zA-Z]'?([a-zA-Z]|\.| |-)+$/,
    masterCard: /^5[1-5][0-9]{14}$/,
    visaCard: /^4[0-9]{12}(?:[0-9]{3})?$/,
    amex: /^3[47][0-9]{13}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
    digits: /^\d{3}/,
    name: /^[a-zA-Z. ]*$/,
    stringWithSpecialCharacters: /^[.a-zA-Z0-9,'!. ]*$/,
    email:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    regEmail:
      /^[a-zA-Z]+(([',.\- ][a-zA-Z ])?[a-zA-Z]*)*\s+&lt;(\w[-._\w]*\w@\w[-._\w]*\w\.\w{2,5})&gt;$|^(\w[-._\w]*\w@\w[-._\w]*\w\.\w{2,5})$/,
    password:
      /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])[a-zA-Z0-9$@$!%*?&_][a-zA-Z0-9$@$!%*?&_]{7,20}$/,
    referralEmail:
      /^[a-z0-9A-Z!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9A-Z!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9A-Z](?:[a-z0-9A-Z]*[a-z0-9A-Z])?\.)+[a-z0-9A-Z](?:[a-z0-9A-Z]*[a-z0-9A-Z])?$/,
  },
  setPageTitle(title) {
    document.title = title;
  },
  redirectToHome() {
    window.location.href = window.location.origin;
  },
  redirectToLogin() {
    window.location.href = `${window.location.origin}${baseEnvironmentConfig.session.defaultLoginUrl}`;
  },
  validateName(value) {
    return !value || !(Utilities.regexExp.name.test(value) && value.length > 1)
      ? "Field is required and must be longer than one character."
      : undefined;
  },
  validateEmail(value) {
    return !value || !Utilities.regexExp.referralEmail.test(value)
      ? "Field is required and must be email"
      : undefined;
  },
  validatePhone(value) {
    return !value || !isValidPhone(value)
      ? "Field is required and must be phone number."
      : undefined;
  },

  isLocalStorageAvailable() {
    const test = "test";
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  },
};

export default Utilities;
