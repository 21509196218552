import { z } from "zod";

export const emailIsValid = (email) => {
  return /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
    email
  );
};

// For 8-20 characters with at least one uppercase, lowercase, digit, and special character from !@#$%^&*
export const PASSWORD_REGEX =
  /(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!@#$%^&*])^[A-Za-z0-9!@#$%^&*]{8,20}$/;

export const isValidPassword = (password) => PASSWORD_REGEX.test(password);

export const isValidZip = (zipCode, country) => {
  let zipRegex;
  if (country.toLowerCase() === "us") {
    zipRegex = /^\d{5}$|^\d{5}-\d{4}/;
  } else if (country.toLowerCase() === "uk") {
    zipRegex =
      /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {0,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR ?0AA)$/;
  } else {
    zipRegex =
      /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i;
  }

  return zipRegex.test(zipCode);
};

// https://en.wikipedia.org/wiki/North_American_Numbering_Plan#Telephone_number_formatting
// https://en.wikipedia.org/wiki/Telephone_numbers_in_the_United_Kingdom#Format
export const PHONE_REGEX = /^[(\d]\d+ ?\d+[)]?[- /]?\d+[- ]?\d+$/;

export const isValidPhone = (phone) => {
  if (
    // eslint-disable-next-line no-useless-escape
    PHONE_REGEX.test(phone) === false
  ) {
    return false;
  }

  const numbers = phone.replace(/\D/g, "");
  if (numbers.charAt(0) == 0) {
    // For UK numbers
    return numbers.length >= 10 && numbers.length <= 11;
  }

  // For US/CA numbers
  return numbers.length === 10;
};

export const NAME_REGEX = new RegExp(
  "^\\p{Letter}[\\p{Letter}0-9 \u2018\u2019`'-.,]*[\\p{Letter}.]+$",
  "u"
);

export const isValidName = (name) => {
  return name.length > 1;
};

// Validation schema for the Contact info inputs (used in referral, contact-info, join flows)
export const ContactInfoFlowSchema = ({
  optionalCompanyEmail = false,
  requiredCompanyEmail = false,
}) => {
  return z.object({
    "First Name": z.string().min(2).trim().regex(NAME_REGEX),
    "Last Name": z.string().min(2).trim().regex(NAME_REGEX),
    "Email Address": z.string().email().min(5).trim(),
    ...(optionalCompanyEmail
      ? {
          "Company Email (optional)": z
            .string()
            .email()
            .min(5)
            .optional()
            .or(z.literal("")),
        }
      : {}),
    ...(requiredCompanyEmail
      ? { "Company Email": z.string().email().min(5).trim() }
      : {}),
    "Zip Code (optional)": z.string().optional(),
    "Phone Number": z
      .string()
      .regex(PHONE_REGEX)
      .trim()
      .refine(
        (val) => {
          const sanitizedValLength = val.replace(/\D/g, "").length;
          return 10 <= sanitizedValLength && sanitizedValLength <= 11;
        },
        {
          message: "Phone number must be 10 or 11 digits long.",
        }
      ),
  });
};

export const CorpJoinFormSchema = z.object({
  "First Name": z
    .string()
    .min(2, { message: "First Name must be at least 2 characters." })
    .trim()
    .regex(NAME_REGEX, { message: "Enter a valid name." }),
  "Last Name": z
    .string()
    .min(2, { message: "Last Name must be at least 2 characters." })
    .trim()
    .regex(NAME_REGEX, { message: "Enter a valid name." }),
  "Personal Email": z
    .string()
    .min(1, { message: "Personal Email is required." })
    .email({ message: "Enter a valid email address." })
    .trim(),
  "Company Email": z
    .string()
    .min(1, { message: "Company Email is required." })
    .email({ message: "Enter a valid email address." })
    .trim(),
  "Phone Number": z
    .string()
    .min(1, { message: "Phone Number is required." })
    .regex(PHONE_REGEX, {
      message: "Phone number must be 10 or 11 digits long.",
    })
    .trim()
    .refine(
      (val) => {
        const sanitizedValLength = val.replace(/\D/g, "").length;
        return 10 <= sanitizedValLength && sanitizedValLength <= 11;
      },
      {
        message: "Phone number must be 10 or 11 digits long.",
      }
    ),
});

export const FormerMemberFlowSchema = z.object({
  "First name": z.string().min(2).trim().regex(NAME_REGEX),
  "Last name": z.string().min(2).trim().regex(NAME_REGEX),
  "Email address": z.string().email().min(5).trim(),
});

export const MailingListFlowSchema = z.object({
  firstName: z.string().min(2).trim().regex(NAME_REGEX),
  lastName: z.string().min(2).trim().regex(NAME_REGEX),
  emailAddress: z.string().email().min(5).trim(),
});

export const GCFlowSchema = z
  .object({
    amount: z.number().max(500),
    customAmount: z.string().optional(),
    firstName: z.string().min(2).trim().regex(NAME_REGEX),
    lastName: z.string().min(2).trim().regex(NAME_REGEX),
    emailAddress: z.string().email().min(5).trim(),
    reTypeEmailAddress: z.string().email().min(5).trim(),
    message: z.string().max(150),
    sendDate: z.date(),
  })
  .refine((data) => data.reTypeEmailAddress === data.emailAddress, {
    path: ["reTypeEmailAddress"],
  })
  .refine(
    (data) =>
      data.amount > -1 ||
      (data.customAmount &&
        !data.customAmount.includes(",") &&
        !data.customAmount.includes(".") &&
        !isNaN(data.customAmount) &&
        parseInt(data.customAmount) > 0 &&
        parseInt(data.customAmount) <= 500),
    {
      path: ["customAmount"],
    }
  );

export const FreezeFlowSchema = z.object({
  medicalReason: z.string(),
  startDate: z.date(),
  freezeDuration: z.string(),
});

export const getDynamicSchema = (formFields) => {
  const schemaObject = {};
  formFields.forEach((field) => {
    if (field && field.fields && field.fields.label) {
      const isSelect = field.fields.formFieldSelectName;
      if (isSelect) {
        schemaObject[field.fields.formFieldSelectName] = field.fields
          .isRequiredField
          ? z.string().min(1)
          : z.string();
        return;
      }

      const isTextArea = field.fields.formFieldTextAreaName;
      if (isTextArea) {
        schemaObject[field.fields.formFieldTextAreaName] = field.fields
          .isRequiredField
          ? z.string().min(1)
          : z.string();
        return;
      }

      const isEmail =
        field.fields.formFieldName === "email" ||
        field.fields.label.toLowerCase() === "email";
      if (isEmail) {
        schemaObject[field.fields.formFieldName] = z
          .string()
          .email()
          .min(5)
          .trim();
        return;
      }

      const isPhone = field.fields.formFieldName === "phone";
      if (isPhone) {
        schemaObject[field.fields.formFieldName] = z
          .string()
          .regex(PHONE_REGEX)
          .trim()
          .refine(
            (val) => {
              const sanitizedValLength = val.replace(/\D/g, "").length;
              return 10 <= sanitizedValLength && sanitizedValLength <= 11;
            },
            {
              message: "Phone number must be 10 or 11 digits long.",
            }
          );
        return;
      }

      const isName = field.fields.label.toLowerCase().includes("name");
      if (isName) {
        schemaObject[field.fields.formFieldName] = z
          .string()
          .min(2)
          .trim()
          .regex(NAME_REGEX);
        return;
      }

      schemaObject[field.fields.formFieldName] = field.fields.isRequiredField
        ? z.string().min(1)
        : z.string();
    }
  });
  return z.object(schemaObject);
};
